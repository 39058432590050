html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
  color: white;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
}

canvas {
  height: 100%;
}

.app {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  font-size: 0;
  text-align: center;
}

.container {
  display: inline-block;
}
